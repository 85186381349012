
import { Component, Vue } from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';
import { RegistrationField, RegistrationFields } from './model';
import { ContactsField, ContactsFields } from './model';
import SignupRequest from '@/models/dto/signup.request';
import GeneralModule from '@/store/modules/general/GeneralModule';
import ROUTES from '@/router/ROUTES';

import Input from '@/components/ui/input/Input.vue';
import Textarea from '@/components/ui/textarea/Textarea.vue';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';
import Button from '@/components/ui/button/Button.vue';
import LanguageSelector from '@/components/ui/language-selector/LanguageSelector.vue';

import Logo from '@/assets/images/icons/logo.svg';
import Notify from '@/services/helpers/notify';
import i18n from '@/services/i18n';

@Component({
  name: 'Registration',
  components: {
    Logo,
    Input,
    Textarea,
    Button,
    Checkbox,
    Icon,
    LanguageSelector,
  },
})
export default class Marks extends Vue {
  public fields: RegistrationFields = {
    company: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    name: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    surname: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    email: {
      value: '',
      isValid: null,
      validator: this.isItEmail,
    },
    password: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    passwordConfirmation: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    consent: {
      value: true,
      isValid: true,
      validator: (value: boolean) => value,
    },
  };

  public contactsFields: ContactsFields = {
    name: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    email: {
      value: '',
      isValid: null,
      validator: this.isItEmail,
    },
    consent: {
      value: true,
      isValid: true,
      validator: (value: boolean) => value,
    },
  };

  public email = '';
  public password = '';
  public passwordConfirmation = '';
  public consent = true;
  public isRegForm = true;

  get isAllFieldsValid(): boolean {
    const keys: string[] = Object.keys(this.fields);
    let isAllValid = true;

    for (const key of keys) {
      const field = this.fields[key];
      isAllValid = isAllValid && field.validator(field.value);
    }

    return isAllValid;
  }

  validate(field: RegistrationField): boolean {
    field.isValid = field.validator(field.value);
    return field.isValid;
  }

  get isContactsFieldsValid(): boolean {
    const keys: string[] = Object.keys(this.contactsFields);
    let isAllValid = true;

    for (const key of keys) {
      const field = this.contactsFields[key];
      isAllValid = isAllValid && field.validator(field.value);
    }

    return isAllValid;
  }

  validateContacts(field: ContactsField): boolean {
    field.isValid = field.validator(field.value);
    return field.isValid;
  }

  clearFalseValidation(field: RegistrationField) {
    field.isValid = field.isValid === false ? null : field.isValid;
  }

  isNotEmpty(value: string): boolean {
    return 0 < value.length;
  }

  isItEmail(value: string) {
    return /.+@.+\..+/.test(value);
  }

  onCompanyChange(value: string): void {
    this.fields.company.value = value;
  }

  onNameChange(value: string): void {
    this.fields.name.value = value;
  }

  onContactsNameChange(value: string): void {
    this.contactsFields.name.value = value;
  }

  onSurnameChange(value: string): void {
    this.fields.surname.value = value;
  }

  onEmailChange(value: string): void {
    this.fields.email.value = value;
  }

  onContactsEmailChange(value: string): void {
    this.contactsFields.email.value = value;
  }

  onPasswordChange(value: string): void {
    this.fields.password.value = value;
  }

  onPasswordConfirmationChange(value: string): void {
    this.fields.passwordConfirmation.value = value;
  }

  onConsentChange(value: boolean) {
    this.fields.consent.value = value;
    this.fields.consent.isValid = value;
  }

  onContactsConsentChange(value: boolean) {
    this.contactsFields.consent.value = value;
    this.contactsFields.consent.isValid = value;
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    const request = new SignupRequest();
    request.email = String(this.fields.email.value);
    request.password = String(this.fields.password.value);
    request.passwordConfirmation = String(this.fields.passwordConfirmation.value);
    request.first_name = String(this.fields.name.value);
    request.lastName = String(this.fields.surname.value);
    request.companyName = String(this.fields.company.value);
    if(this.validateFields(request))
    {
      GeneralModule.signupRequest = request;
      const res = await GeneralModule.signup();
      this.$router.push(ROUTES.HOME);
    }
    
  }
  validateFields(request: SignupRequest)
  {
    if(request.password != request.passwordConfirmation)
    {
      Notify.error(i18n.t('Пароли не совпадают').toString());
      return false
    }
    if(request.password.length <= 7)
    {
      Notify.error(i18n.t('Пароль должен содержать 8 символов').toString());
      return false
    }
    const russianRegex = /[а-яА-ЯЁё]/;
    if(russianRegex.test(request.password))
    {
      Notify.error(i18n.t('Только латинские символы').toString());
      return false
    }
    return true
  }
  onSend(e: Event): void {
    e.preventDefault();
  }

  changeForm() {
    this.isRegForm = !this.isRegForm;
  }
}
